import configurationJson from '../data/configuration.json';
import pagesOrderJSON from '../data/page-order.json';
import pagesJSON from '../data/menu/*.json';
import type { IActionItem, IMenuItem } from './components/layout';

interface IActions {
    shop: IActionItem;
    order: string[];
}

interface IPages {
    home: IMenuItem;
    contatti: IMenuItem;
}

interface IConfiguration {
    title: string;
    banner?: string;
    actions: IActions;
    pages: IPages;
    address: string;
    copyright: string;
}

export const configuration: IConfiguration = configurationJson;

interface IPageSlug {
    menu: string;
}

interface IPageOrder {
    pages: IPageSlug[];
}

const pageOrder: IPageOrder = pagesOrderJSON;

export const menuPages: IMenuItem[] = pageOrder.pages.map(
    ({ menu }) => ({ url: `/${menu}`, title: pagesJSON[menu]?.title })
);
